import { BsWhatsapp } from "react-icons/bs";
import { WHATSAPP_HREF } from "../utils/constants";
import FollowUsNotification from "./FollowUsNotification";
import Footer from "./Footer";
import Navbar from "./Navbar";

function WhatsappWidget() {
  return (
    <span className="sticky bottom-0 right-0 flex justify-end p-4 z-20">
      <a
        href={WHATSAPP_HREF}
        target="_blank"
        rel="noreferrer"
        className="h-12 w-12 bg-green-500 rounded-full shadow-lg flex items-center justify-center hover:scale-110 transition-all cursor-pointer"
      >
        <BsWhatsapp className="h-8 w-8 pt-0.5 text-white -translate-y-0.5" />
      </a>
    </span>
  );
}

export default function Layout({ children }) {
  return (
    <div className="relative">
      <Navbar />
      <main className="bg-white">{children}</main>
      <Footer />
      {/* <WhatsappWidget /> */}
      {/* <FollowUsNotification /> */}
    </div>
  );
}
