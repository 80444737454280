import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../utils/formatters";

export default function VerifiedUserBadge({
  idVerified,
}: {
  idVerified?: boolean;
}) {
  const Icon = idVerified ? CheckBadgeIcon : ExclamationTriangleIcon;
  return (
    <span
      className={classNames(
        "inline-flex items-center gap-x-1.5 rounded-full  pl-1.5 pr-2 py-0.5 text-sm font-medium text-white",
        idVerified ? "bg-cyan-500" : "bg-yellow-500"
      )}
    >
      <Icon className="text-white h-5 w-5" />
      Identidad {idVerified ? "verificada" : "no verificada"}
    </span>
  );
}
