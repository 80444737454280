/* This example requires Tailwind CSS v2.0+ */

/* user declaration in line 65 is being casted to type "{}" automatically so down the file when
    trying to access properties from user, typescript is throwing errors. 
    I could why is begin directly casted into "{}" type.
 */
import { Disclosure } from "@headlessui/react";
import { HeartIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import Link from "next/link";
import SearchField from "./SearchField";
import Image from "next/image";
import { TopPageBanner } from "./TopPageBanner";
import { ID_VERIFICATION_STATUSES } from "../utils/constants";
import ProfileDropdown from "./ProfileDropdown";
import SellButton from "./SellButton";
import SideMenu from "./SideMenu";

export default function Navbar() {
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;

  return (
    <>
      <div className="sticky top-0 z-40">
        <Disclosure as="nav" className="bg-white shadow-md">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 pb-3 sm:pb-0">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-orange-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex-shrink-0 flex items-center cursor-pointer">
                      <Link href="/">
                        {/* <div className="hidden sm:flex lg:hidden">
                    <Image
                      src="/movi_logo_min.png"
                      alt="Movi"
                      width={62}
                      height={32}
                    />
                  </div> */}
                        <div className="flex">
                          <Image
                            src="/movi_logo.png"
                            alt="Logo Movi"
                            width={140}
                            height={32}
                          />
                        </div>
                      </Link>
                    </div>
                    {/* <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      aria-current={
                        item.href === router.pathname ? "page" : undefined
                      }
                    >
                      <span
                        className={classNames(
                          item.href === router.pathname
                            ? "bg-white text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-gray-500",
                          "px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </div> */}
                  </div>
                  <div className="hidden sm:flex flex-1 items-center mx-2 justify-end sm:justify-start ">
                    <SearchField />
                  </div>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <SellButton />
                    </div>
                    <div className="hidden md:ml-2 md:flex-shrink-0 md:flex md:items-center gap-x-3">
                      {/* <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 mx-2"
                  >
                    ENG
                  </button> */}

                      {session.status === "authenticated" ? (
                        <Link href="/favorites" className="mx-2 hidden lg:flex">
                          <div className="cursor-pointer bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 focus:outline-none flex flex-col items-center">
                            <span className="sr-only">Favoritos</span>
                            <HeartIcon className="h-6 w-6" aria-hidden="true" />
                            <span className="text-xxs">Favoritos</span>
                          </div>
                        </Link>
                      ) : null}

                      {/* Profile dropdown */}

                      <ProfileDropdown />
                    </div>
                  </div>
                </div>

                <div className="flex flex-1 items-center sm:hidden">
                  <SearchField />
                </div>
              </div>

              <SideMenu />
            </>
          )}
        </Disclosure>
      </div>

      {user?.idVerificationStatus === ID_VERIFICATION_STATUSES.unverified ? (
        <TopPageBanner
          title="Verificá tu identidad"
          description="Tus publicaciones tendrán mayor visibilidad"
          buttonText="Verificar"
          buttonLink="/profile/verify"
          hideOnRoutes={["/profile", "/vender"]}
          bgColor="bg-yellow-400"
        />
      ) : (
        <TopPageBanner
          title="¿Vendiendo tu auto?"
          description="Conocé su valor con nuestra nueva herramienta"
          buttonText="Conocer"
          buttonLink="/valor-de-mi-auto"
          hideOnRoutes={[
            "valor-de-mi-auto",
            "/profile",
            "/vender",
            "/destacar",
            "/checkout",
          ]}
          showGradients
        />
      )}
    </>
  );
}
