import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import Avatar from "./Avatar";
import { User as SessionUser } from "next-auth";
import { User } from "../graphql/generated/graphql";
import VerifiedUserBadge from "./VerifiedUserBadge";
import { classNames } from "../utils/formatters";
import Spinner from "./Spinner";
import { isInactive, isUnauthorized } from "../utils/connect";
export default function UserInfoBadge({
  user,
  nav,
  loading,
}: {
  user: User | SessionUser;
  nav?: boolean;
  loading?: boolean;
}) {
  let displayName = user?.displayName ? user.displayName : user?.username;

  if (user?.company?.name) {
    displayName = user.company.name;
  }

  const idVerified = user?.idVerified;

  return (
    <div
      className={classNames(
        "flex items-center",
        nav ? "gap-x-2" : "flex-col gap-y-2 "
      )}
    >
      {!nav ? (
        <p className="leading-6 text-gray-600">Este auto es vendido por</p>
      ) : null}

      <div className="flex-shrink-0 sm:mb-0">
        {loading ? (
          <Spinner containerClassName="h-48 flex items-center" />
        ) : (
          <Avatar
            src={user.company?.logo ? user.company.logo : user.image}
            username={user.username}
            size={nav ? "medium" : "large"}
          />
        )}
      </div>

      {!loading ? (
        <div className="flex flex-col gap-y-2 items-center divide-y divide-gray-200">
          <h4 className="text-lg font-bold">{loading ? "" : displayName}</h4>
          <div className="flex gap-x-2 items-center pt-4 pb-1.5">
            <VerifiedUserBadge idVerified={idVerified} />
            {!isUnauthorized(user) && !isInactive(user) ? (
              <div>
                <Image
                  src="/connect_logo_h.png"
                  alt="Logo Movi Connect"
                  width={96}
                  height={28.8}
                />
              </div>
            ) : null}
          </div>
          <div className="w-full flex justify-center py-4">
            {!nav && !loading ? (
              <Link href={`/${user.username}`}>
                <span className="text-cyan-500 hover:text-cyan-400 flex gap-x-1 items-center cursor-pointer font-medium">
                  Ver página del vendedor
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 " />
                </span>
              </Link>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
