import { Disclosure } from "@headlessui/react";
import { classNames } from "../utils/formatters";
import UserInfoBadge from "./UserInfoBadge";
import { getUserNavigation } from "./ProfileDropdown";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { NAVIGATION, SUBSCRIPTION_TYPES } from "../utils/constants";
import Link from "next/link";
import Image from "next/image";

export default function SideMenu() {
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;
  const router = useRouter();

  const userNavigation = getUserNavigation(user);

  return (
    <Disclosure.Panel className="md:hidden">
      <div className="pt-4 pb-3 border-t border-gray-300">
        <div className="flex items-center px-5 sm:px-6">
          {user ? (
            <div className="flex-shrink-0">
              <UserInfoBadge user={user} nav />
            </div>
          ) : null}
        </div>
        <div className="mt-3 px-2 space-y-1 sm:px-3">
          {userNavigation.map((item) =>
            item.href ? (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={classNames(
                  "block px-3 py-2 rounded-md text-base font-medium",
                  item.href === router.pathname ||
                    (item.href === `/${user?.username}` &&
                      router.pathname === "/[username]")
                    ? "bg-white text-orange-500"
                    : "text-gray-700 hover:bg-gray-100"
                )}
              >
                {item.name}
              </Disclosure.Button>
            ) : (
              <Disclosure.Button
                key={item.name}
                as="button"
                onClick={item.onClick}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700  hover:bg-gray-100 w-full text-left"
              >
                {item.name}
              </Disclosure.Button>
            )
          )}
        </div>
      </div>
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t border-t-gray-300">
        {NAVIGATION.map((item) => (
          <Disclosure.Button
            key={item.name}
            as="a"
            href={item.href}
            className={classNames(
              item.href === router.pathname
                ? "bg-white text-orange-500"
                : "text-gray-700 hover:bg-gray-100",
              "block px-3 py-2 rounded-md text-base font-medium"
            )}
            aria-current={item.href === router.pathname ? "page" : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
        {user?.subscription?.type === SUBSCRIPTION_TYPES.connect ? (
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t border-t-gray-300">
            <Link href="/connect" passHref={true}>
              <div
                className={classNames(
                  router.pathname === "/connect"
                    ? "bg-white text-orange-500"
                    : "text-gray-700 hover:bg-gray-100",
                  "block py-2 rounded-md text-base font-medium"
                )}
              >
                <Image
                  src="/connect_logo_h.png"
                  alt="Logo Movi Connect"
                  width={100}
                  height={30}
                />
              </div>
            </Link>
          </div>
        ) : null}
      </div>
    </Disclosure.Panel>
  );
}
